'use client'

import React, { memo } from 'react'
import Link from 'next/link'

type Size = 'small' | 'medium' | 'large' | 'xlarge'
type Background = 'visible' | 'hidden'
type FullWidth = 'full' | 'nonefull'

type LinkButtonProps = {
  href?: string
  size?: Size
  bg?: Background
  full?: FullWidth
  border?: 'none' | ''
  more?: boolean
  label: string
  blank?: boolean
  onClick?: () => void
}

const getButtonSize = (size: Size, more: boolean = false): string => {
  const sizeClasses = {
    small: 'linkbutton__small',
    medium: `linkbutton__medium ${more ? 'button__more' : ''}`,
    large: 'linkbutton__large',
    xlarge: 'linkbutton__xlarge'
  }
  return sizeClasses[size] || ''
}

const getBackgroundClass = (bg: Background): string => {
  const bgClasses = {
    visible: 'button__visible',
    hidden: 'button__hidden'
  }
  return bgClasses[bg] || ''
}

const getFullWidthClass = (full: FullWidth): string => {
  const fullClasses = {
    full: 'button__full',
    nonefull: 'button__nonefull'
  }
  return fullClasses[full] || ''
}

const getButtonClasses = (
  size: Size,
  bg: Background,
  full: FullWidth,
  border: string,
  more: boolean
): string => {
  return `
    base_button
    ${getButtonSize(size, more)}
    ${getBackgroundClass(bg)}
    ${border ? 'button__none' : ''}
    ${getFullWidthClass(full)}
    transition
    ${
      bg === 'hidden'
        ? 'hover:text-secondary hover:border-secondary'
        : 'hover:bg-secondary hover:border-secondary'
    }
  `
    .trim()
    .replace(/\s+/g, ' ')
}

export const LinkButton = memo(
  ({
    href = '/',
    size = 'medium',
    bg = 'visible',
    full = 'nonefull',
    border = '',
    more = false,
    blank = false,
    label,
    onClick
  }: LinkButtonProps) => {
    const buttonClasses = getButtonClasses(size, bg, full, border, more)

    if (blank) {
      return (
        <Link
          href={href}
          className={buttonClasses}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {label}
        </Link>
      )
    }

    if (href.startsWith('#')) {
      return (
        <Link
          href={href}
          className={`pagescroll ${buttonClasses}`}
          onClick={onClick}
        >
          {label}
        </Link>
      )
    }

    return (
      <Link href={href} className={buttonClasses} onClick={onClick}>
        {label}
      </Link>
    )
  }
)

LinkButton.displayName = 'LinkButton'

export default LinkButton
