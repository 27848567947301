interface GtmDataLayer {
  push: (obj: any) => void
}

export const getDataLayer = (): GtmDataLayer => {
  return (
    (window as Window & { dataLayer?: GtmDataLayer }).dataLayer ?? {
      push: () => {},
    }
  )
}

export const useCLickEvent = () => {
  return {
    pushGa4ClickEvent: (eventObj: {
      eventCategory: string // 何をクリックしたかなど
      eventUi: string // UI
    }) => {
      try {
        getDataLayer().push({
          event: 'internal_action',
          eventAction: 'click',
          ...eventObj,
        })
      } catch (e: any) {
        // NOP
      }
    },
  }
}
