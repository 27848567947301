import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

export const useFromParams = () => {
  const pathname = usePathname()

  const params = useMemo(() => {
    if (!pathname) return ''
    if (pathname === '/') return '?from=home'
    return `?from=${pathname.replaceAll('/', '')}`
  }, [pathname])

  return params
}
