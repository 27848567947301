'use client'
import Link from 'next/link'
import React, { useEffect, useMemo, useState } from 'react'

import { usePathname } from 'next/navigation'
import { useFromParams } from '@/app/hooks/use-from-params'

interface ButtonDemoProps {
  onClick?: () => void
  sublabel: string
  label: string
  variant?: 'primary' | 'secondary'
}

const DemoLinkButton: React.FC<ButtonDemoProps> = ({
  onClick,
  sublabel,
  label,
  variant = 'primary',
}) => {
  const [isDesktop, setIsDesktop] = useState(false)

  const params = useFromParams()

  useEffect(() => {
    const checkIsDesktop = () => {
      const userAgent = window.navigator.userAgent.toLowerCase()
      const mobileDevices = [
        'iphone',
        'ipad',
        'android',
        'blackberry',
        'windows phone',
        'mobile',
        'tablet',
      ]
      const isMobile = mobileDevices.some((device) =>
        userAgent.includes(device)
      )
      setIsDesktop(!isMobile)
    }
    checkIsDesktop()
  }, [])

  if (!isDesktop) {
    return null
  }

  const styles = {
    primary: {
      button:
        'relative border-2 border-primary rounded-full font-bold linkbutton__xlarge button__hidden button__nonefull transition hover:border-secondary hover:text-secondary text-center group hidden lg:inline-block',
      badge:
        'h-6 px-4 bg-primary rounded-full justify-center items-center inline-flex transition-all duration-150 border border-primary',
      badgeText: 'text-center text-white text-xs font-bold',
    },
    secondary: {
      button:
        'relative border-2 rounded-full font-bold linkbutton__xlarge button__hidden button__nonefull transition hover:bg-primary hover:border-primary hover:text-white text-center group hidden lg:inline-block',
      badge:
        'h-6 px-4 bg-primary rounded-full justify-center items-center inline-flex transition-all duration-150 group-hover:bg-white border border-primary group-hover:border-primary',
      badgeText:
        'text-center text-white text-xs font-bold group-hover:text-primary',
    },
  }

  const currentStyles = styles[variant]

  return (
    <Link
      href={`/demo/${params}`}
      className={currentStyles.button}
      onClick={onClick}
    >
      <div className="w-full absolute -top-4 left-0">
        <div className={currentStyles.badge}>
          <div className={currentStyles.badgeText}>{sublabel}</div>
        </div>
      </div>
      {label}
    </Link>
  )
}

export default DemoLinkButton
