'use client'
import { Header } from '../components/layouts/Header'
import { Footer } from '../components/layouts/Footer'
import { Popup } from '../components/parts/Popup'
import { usePathname } from 'next/navigation'
import { Provider } from 'react-redux'
import { store } from '@/store/store'

const hideHeader = ['/demo/[slug]', '/preview-newt/demo']
const hideFooter = ['/demo/[slug]', '/preview-newt/demo']
const hidePopupRoutes = [
  '/thanks',
  '/contact',
  '/request',
  '/demo/[slug]',
  '/preview-newt/demo'
]

export default function GroupLayout({
  children
}: {
  children: React.ReactNode
}) {
  const pathname = usePathname() ?? ''

  const matchDynamicRoute = (route: string, path: string) => {
    const routeParts = route.split('/')
    const pathParts = path.split('/')

    if (routeParts.length == pathParts.length) return false

    return routeParts.every((part, i) => {
      if (part.startsWith('[') && part.endsWith(']')) return true
      return part === pathParts[i]
    })
  }

  const showHeader = !hideHeader.some((route) =>
    matchDynamicRoute(route, pathname)
  )
  const showFooter = !hideFooter.some((route) =>
    matchDynamicRoute(route, pathname)
  )
  const showPopup = !hidePopupRoutes.some((route) => {
    if (route.includes('[slug]')) {
      return matchDynamicRoute(route, pathname)
    }
    return pathname.startsWith(route)
  })

  return (
    <>
      <Provider store={store}>
        {showHeader && <Header path={pathname} />}
        <div className="l-wrapper">
          <main>{children}</main>
        </div>
        {showFooter && <Footer />}
        {showPopup && (
          <div className="hidden lg:inline-block lg:fixed lg:bottom-4 lg:left-4 z-50">
            <Popup />
          </div>
        )}
      </Provider>
    </>
  )
}
