import React, { useRef, useState, useEffect } from 'react'

const VideoEmbed = ({ videoId, startTime }) => {
  const iframeId = `youtube_iframe_${videoId}`
  const containerRef = useRef(null)
  const [isInViewport, setIsInViewport] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInViewport(entry.isIntersecting)
      },
      { threshold: 0.5 }
    )

    const currentContainerRef = containerRef.current
    if (currentContainerRef) {
      observer.observe(currentContainerRef)
    }

    return () => {
      if (currentContainerRef) {
        observer.unobserve(currentContainerRef)
      }
    }
  }, [])

  useEffect(() => {
    const iframe = document.getElementById(iframeId) as HTMLIFrameElement
    if (iframe && iframe.contentWindow) {
      if (isInViewport) {
        iframe.contentWindow.postMessage(
          '{"event":"command","func":"playVideo","args":""}',
          '*'
        )
      } else {
        iframe.contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          '*'
        )
      }
    }
  }, [isInViewport, iframeId])

  return (
    <div ref={containerRef} className="video-wrap">
      <div className="video">
        <iframe
          id={iframeId}
          className="observe w-full"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=0&mute=1&loop=1&playlist=${videoId}&start=${startTime}&playsinline=1&enablejsapi=1`}
          title="LAWGUEサービス紹介"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        />
      </div>
    </div>
  )
}

const VideoDescription = () => (
  <div className="w-[436px] ml-[60px] maxsm:ml-0 maxlg:ml-[40px] maxsm:mt-6">
    <h2 className="title text-left text-textblack text-xl maxsm:text-lg maxsm:text-center font-bold">
      AIによるサポートで、
      <br />
      文書作成における
      <br className="maxsm:hidden" />
      非効率な作業から
      <br className="lg:hidden" />
      あなたを開放します
    </h2>
    <p className="lead text-left maxsm:text-center text-base maxsm:text-base leading-7 mt-6">
      クラウド ドキュメント
      ワークスペースによる新しいドキュメント作成は、あなたを従来の文書作成における非効率な作業から解放します。
      <br className="maxsm:hidden" />{' '}
      本当に時間を割くべき業務に集中し、大幅に業務を改善できます。
    </p>
  </div>
)

export const Video = () => {
  return (
    <section className="bg-lawguebase">
      <div className="text-center mx-auto sec_inner_w60 pt-20 pb-20 maxsm:pt-10 maxsm:pb-10 maxsm:px-6 mt-6 maxsm:w-full">
        <div className="flex maxsm:flex-wrap justify-center">
          <VideoEmbed videoId="7VCaIXcQe3Q" startTime="6" />
          <VideoDescription />
        </div>
      </div>
    </section>
  )
}
