import Link from 'next/link'
import type { News } from '@/types/newt/news'
import { Time } from '@/components/parts/time'

export function NewsList({ news }: { news: News }) {
  return (
    <>
      <div className="border-b border-gray border-solid py-4 first:pt-0 text-left">
        <div className="lg:flex lg:flex-nowrap items-baseline">
          <div className="flex items-baseline">
            <Time gmt={news.overwriteCreatedAt} className={'mr-4 text-base'} />
            <p className="w-[6rem] text-center mx-1 px-1 rounded-sm text-xs text-white font-bold bg-secondary mr-4">
              {news.newsCategory}
            </p>
          </div>
          <Link
            className="flex-1 lg:transition lg:hover:opacity-80 block text-base"
            href={`/news/${news.slug}`}
          >
            {news.title}
          </Link>
        </div>
      </div>
    </>
  )
}
