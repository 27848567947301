'use client'

import React, { memo } from 'react'
import Link from 'next/link'
import { useCLickEvent } from '@/hooks/use-tracker'

export const Footer = memo(() => {
  const { pushGa4ClickEvent } = useCLickEvent()
  return (
    <footer className={`bg-darken maxsm:mb-[58px]`}>
      <div className="lg:px-8 lg:py-16 maxlg:px-4 px-6 py-10">
        <h3 className="">
          <img src="/images/img_logo02.svg" alt="LAWGUE" />
        </h3>
        <div className="flex flex-wrap items-baseline mt-10">
          <div className="lg:flex flex-wrap items-baseline maxsm:w-6/12">
            <ul>
              <li>
                <div>
                  <span className="text-white text-sm font-bold">
                    ソリューション
                  </span>
                  <div className="flex flex-col items-baseline">
                    <Link
                      href="/contract"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      契約ナレッジマネジメント
                    </Link>
                    <Link
                      href="/regulation"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      規程・マニュアル管理
                    </Link>
                    <Link
                      href="/law"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      法令改正対応
                    </Link>
                    <Link
                      href="/translation"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      英文契約書レビュー
                    </Link>
                    <Link
                      href="https://d1.lawgue.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80 flex"
                    >
                      省庁・自治体向け
                      <img
                        src="/images/img_icon_externallink-white.svg"
                        alt=""
                        className="ml-2"
                      />
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="lg:ml-14 maxsm:mt-5">
              <li>
                <div>
                  <span className="text-white text-sm font-bold">製品紹介</span>
                  <div className="flex flex-col items-baseline">
                    <Link
                      href="/functions"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      機能
                    </Link>
                    <Link
                      href="/security"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      セキュリティ
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="lg:ml-14 maxsm:mt-5">
              <li>
                <Link
                  href="/flow"
                  className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                >
                  料金・導入の流れ
                </Link>
              </li>
            </ul>
          </div>
          <div className="lg:flex flex-wrap items-baseline maxsm:w-6/12">
            <ul className="lg:ml-14 maxsm:mt-5">
              <li>
                <div>
                  <Link
                    href="/voice"
                    className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                  >
                    導入事例
                  </Link>
                </div>
              </li>
            </ul>
            <ul className="lg:ml-14 maxsm:mt-5">
              <li>
                <div>
                  <span className="text-white text-sm font-bold">
                    お役立ち情報
                  </span>
                  <div className="flex flex-col items-baseline">
                    <Link
                      href="/service"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      お役立ち資料
                    </Link>
                    <Link
                      href="/seminar"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      セミナー
                    </Link>
                    <Link
                      href="/column"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      コラム
                    </Link>
                    <Link
                      href="/news"
                      className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                    >
                      お知らせ
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
            <ul className="lg:ml-14 maxsm:mt-5">
              <li>
                <div>
                  <Link
                    href="/partnership"
                    className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
                  >
                    パートナーシップ
                  </Link>
                </div>
              </li>
            </ul>
            <ul className="lg:ml-14 maxsm:mt-5">
              <li className="mt-2">
                <Link
                  href="/contact"
                  className="text-white text-sm font-bold lg:transition lg:hover:opacity-80"
                  onClick={() => {
                    // TODO separate
                    pushGa4ClickEvent({
                      eventCategory: 'contact',
                      eventUi: 'footer'
                    })
                  }}
                >
                  お問い合わせ
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="items-center lg:justify-between flex lg:px-8 lg:py-10 maxlg:px-4 px-6 py-10 border-t border-solid border-white maxsm:flex-col maxsm:items-start">
        <div className="maxsm:order-2 maxsm:mt-10">
          <p className="text-white text-xs">© 2023 FRAIM株式会社</p>
        </div>
        <div className="maxsm:order-1">
          <ul className="items-center lg:flex">
            <li>
              <Link
                href="https://fraim.co.jp/"
                target="_blank"
                rel="noreferrer"
                className="text-white text-xs lg:ml-6 lg:transition lg:hover:opacity-80"
              >
                運営会社
              </Link>
            </li>
            <li className="maxsm:mt-2">
              <Link
                href="/terms"
                className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
              >
                利用規約
              </Link>
            </li>
            <li className="maxsm:mt-2">
              <Link
                href="/privacy-policy"
                className="text-white text-xs mt-2 lg:transition lg:hover:opacity-80"
              >
                プライバシーポリシー
              </Link>
            </li>
            <li className="maxsm:mt-2">
              <Link
                href="https://fraim.co.jp/security/"
                target="_blank"
                rel="noreferrer"
                className="text-white text-xs lg:ml-6 lg:transition lg:hover:opacity-80"
              >
                情報セキュリティ方針
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
})

Footer.displayName = 'Footer'
