'use client'

import React, { memo } from 'react'
import { LinkButton } from '../link-button'
import clsx from 'clsx'
import { useCLickEvent } from '@/hooks/use-tracker'

type ContactType = {
  bg: string
}

export const Contact: React.FC<ContactType> = memo(({ bg }) => {
  const { pushGa4ClickEvent } = useCLickEvent()
  return (
    <section
      className={clsx(bg, 'overflow-hidden')}
      id="contact"
      data-observe-item
    >
      <div className="text-center w-60 mx-auto py-20 maxsm:w-full maxsm:pb-10 maxsm:pt-10">
        <div className="flex flex-col-reverse justify-center relative">
          <h2 className="text-2xl font-bold maxsm:text-base">
            お気軽にお問い合わせ・ご相談ください
          </h2>
          <img
            src="/images/img_contact.svg"
            alt=""
            className="w-[4.5rem] mx-auto mb-3 lg:w-[140px] lg:absolute lg:top-0 lg:right-0"
          />
        </div>
        <div className="mt-10 maxsm:mt-4">
          <LinkButton
            href="/contact"
            size="xlarge"
            bg="visible"
            label="お問い合わせ・相談する"
            onClick={() => {
              pushGa4ClickEvent({
                eventCategory: 'contact',
                eventUi: 'footer-button',
              })
            }}
          />
        </div>
      </div>
    </section>
  )
})

Contact.displayName = 'Contact'
