import React from 'react'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import { MainLogos } from './MainLogos'
import { getLogosList, newtClient } from '@/utils/newt/newt'
import { Logos } from '@/types/newt/logos'
import { useCLickEvent } from '@/hooks/use-tracker'
import LinkButton from '../../parts/LinkButton'
import DemoLinkButton from '../../parts/DemoLinkButton'
import { Popup } from '../../parts/Popup'

const PointMessage = () => (
  <p className="flex justify-center">
    <picture>
      <source
        srcSet="/images/img_main_point_message_sp.svg"
        media="(max-width: 860px)"
      />
      <img
        decoding="async"
        src="/images/img_main_point_message.svg"
        alt="サポート満足度99%以上 業務削減効果実感94%以上"
      />
    </picture>
  </p>
)

export const Visual = ({
  logos
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { pushGa4ClickEvent } = useCLickEvent()
  return (
    <section className="mt-10 lg:mt-12">
      <div className="text-center px-1 md:px-0 relative mx-auto w-full xl:w-[1280px]">
        <div className="main_hero overflow-hidden">
          <h1 className="title text-center text-textblack text-2xl md:text-4xlh font-bold">
            採用するなら、
            <br />
            追加人員よりLAWGUE
          </h1>
          <div className="relative">
            <p className="lead text-center text-base md:text-lg leading-7 md:leading-8 mt-3">
              クラウドドキュメント
              <br className="block md:hidden" />
              ワークスペース「LAWGUE」で
              <br />
              契約書・規程・開示文書など
              <br />
              様々な文書の作成・検索・レビューを効率化
            </p>
            <img
              decoding="async"
              src="/images/img_illust03.svg"
              alt=""
              className="absolute top-auto h-[70px] bottom-[37px] mt-[1rem] mr-[0.5rem] right-0 md:h-auto md:top-[-1rem] md:mr-[-1.75rem]"
            />
          </div>
          <div className="hidden lg:block">
            <PointMessage />
          </div>
          <div className="mt-8 lg:mt-5 flex gap-3 lg:gap-4 justify-center px-1.5 max-w-[360px] lg:max-w-[640px] mx-auto">
            <DemoLinkButton
              label="無料デモを体験"
              sublabel="LAWGUEを今すぐ試す！"
              variant="primary"
            />
            <LinkButton
              href="/request"
              size="xlarge"
              bg="visible"
              label="資料をダウンロード"
              onClick={() => {
                pushGa4ClickEvent({
                  eventCategory: 'request',
                  eventUi: 'top-main-visual'
                })
              }}
            />
          </div>
          <div className="inline-block lg:hidden lg:fixed lg:bottom-4 lg:left-4 z-50">
            <Popup />
          </div>
        </div>
        <div className="text-right mt-7 text-[10px] w-[80%] xl:w-[1030px] mx-auto hidden md:block">
          <p>※2021年12月～2022年1月 LAWGUEユーザーアンケート</p>
        </div>
        <div className="grid grid-cols-4 lg:grid-cols-8 gap-2 justify-between mb-2 mt-6 md:mt-5 mx-auto w-full xl:w-[1280px] px-2 lg:px-6">
          {logos &&
            logos.map((logos: Logos) => (
              <MainLogos key={logos._id} logos={logos} />
            ))}
        </div>
        <div className="block lg:hidden">
          <PointMessage />
        </div>
        <div className="text-center text-[10px] mx-auto w-[80%] xl:w-auto block md:hidden">
          <p>※2021年12月～2022年1月 LAWGUEユーザーアンケート</p>
        </div>
        <div className="w-full lg:w-[690px] mx-auto relative">
          <img
            decoding="async"
            src="/images/img_main_hero.png"
            alt=""
            className="maxsm:w-10/12 maxsm:mx-auto"
          />
          <img
            decoding="async"
            src="/images/img_illust01.svg"
            alt=""
            className="absolute ml-auto mr-auto top-[10%] left-[3%] w-[9%] lg:top-[6rem] lg:left-[-5rem] lg:w-[80px]"
          />
        </div>
        <picture>
          <source srcSet="/images/img_main_bg.svg" media="(min-width: 860px)" />
          <img
            decoding="async"
            src="/images/img_main_bg.svg"
            alt=""
            className="-mt-10 absolute top-0 left-1/2 -translate-x-1/2 -z-10 maxsm:mt-[53%]"
          />
        </picture>
      </div>
    </section>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const [{ logos }] = await Promise.all([
    getLogosList({
      client: newtClient('cdn'),
      query: { order: ['-_sys.customOrder'] }
    })
  ])

  return {
    props: {
      logos
    }
  }
}

export default Visual
