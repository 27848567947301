import React from 'react'

const ReasonBox = ({ number, title, description, imageSrc }) => (
  <div className="simple box bg-transparent column_w280 maxsm:w-full">
    <div className="flex justify-center flex-col-reverse items-center">
      <div>
        <div className="flex flex-col-reverse items-center">
          <h3 className="text-center maxsm:text-center text-2xl maxsm:text-xl text-textblack font-bold fontfeaturesettings">
            {title}
          </h3>
          <p className="text-center maxsm:text-center text-5xl maxsm:text-4xl text-primary fontfeaturesettings mt-0 font-robot">
            {number}
          </p>
        </div>
        <p className="lead text-center text-base maxsm:text-sm mt-3 text-textblack">
          {description}
        </p>
      </div>
      <figure>
        <img decoding="async" src={imageSrc} alt="" />
      </figure>
    </div>
  </div>
)

export const Reason = () => {
  const reasons = [
    {
      number: '01',
      title: (
        <>
          直感的でシンプルな
          <br />
          画面操作
        </>
      ),
      description: (
        <>
          難しい操作はいりません。
          <br />
          LAWGUEではほとんどの作業をクリックひとつで完了できます
        </>
      ),
      imageSrc: '/images/img_contract_top01.svg'
    },
    {
      number: '02',
      title: (
        <>
          様々な業界・事業規模
          <br className="lg:hidden" />
          での導入実績
        </>
      ),
      description:
        '数百社を超える企業、弁護士法人、社会保険労務士事務所、自治体、官公庁等、幅広い導入実績があります',
      imageSrc: '/images/img_contract_top02.svg'
    },
    {
      number: '03',
      title: (
        <>
          万全の
          <br />
          導入サポート
        </>
      ),
      description:
        'システムは入れるだけでは意味がありません。導入から運用開始まで、専任担当者がしっかりサポートします',
      imageSrc: '/images/img_contract_top03.svg'
    }
  ]

  return (
    <section>
      <div className="text-center mx-auto pt-40 pb-20 maxsm:pb-10 maxsm:pt-10 sec_inner_w60 maxsm:w-full maxsm:px-6">
        <h2 className="text-center maxsm:text-center text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings">
          LAWGUEが選ばれる理由
        </h2>
        <div className="flex flex-wrap items-sketch justify-center mt-14 maxsm:mt-6 column60 column60_col3">
          {reasons.map((reason, index) => (
            <ReasonBox key={index} {...reason} />
          ))}
        </div>
      </div>
    </section>
  )
}
