import React from 'react'

const ResolveItem = ({ number, title, description, imageSrc, isReverse }) => (
  <div
    className={`sm:flex justify-between lg:mt-20 maxsm:mt-10 ${
      isReverse ? 'flex-row-reverse' : ''
    } `}
  >
    <div className="w-[47.5%] maxsm:w-full">
      <div className="flex flex-col-reverse items-start">
        <h3
          className="text-left maxsm:text-left text-4xl maxsm:text-lg text-textblack font-bold fontfeaturesettings"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className="font-bold text-4xl maxsm:text-4xl leading-1">
          <span className="before:bg-[#E7DBCC] before:absolute before:top-[50%] before:mt-[2px] before:left-0 before:w-[110px] before:h-[30px] before:z-[-1] before:-translate-y-1/2 relative font-robot even:flex-row-reverse">
            {number.padStart(2, '0')}
          </span>
        </p>
      </div>
      <p
        className="lead text-left text-base maxsm:text-base leading-relaxed mt-3"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
    <div className="lg:w-6/12 maxsm:w-full maxsm:mt-4 lg:w-[47.5%]">
      <img decoding="async" src={imageSrc} alt="" className="w-full" />
    </div>
  </div>
)

export const Resolve = () => {
  const resolveItems = [
    {
      number: '1',
      title: '今見たい文書を、いますぐ発見',
      description:
        '1年で150時間。これはビジネスパーソンが、<br className="maxsm:hidden">「書類探し」に費やしている時間です※。<br className="maxsm:hidden">LAWGUEなら、見たいと思った文書を、<br>AIが先回りして発見。しかもその間、たった2秒。<br className="maxsm:hidden">貴重な時間をムダにしません。<br><span className="lead text-left undefined text-xs undefined undefined undefined mt-2 undefined text-undefined">※弊社調べ</span>',
      imageSrc: '/images/img_top_task01.png',
      isReverse: false
    },
    {
      number: '2',
      title: '「なんでこうなったんだっけ？」を、まるごと記憶',
      description:
        '文書内のコメントを全部消して、<br className="maxsm:hidden">あらたに書いて、メールに添付して･･･<br>そんな面倒から、あなたを解放。 <br>すべてのやりとりがLAWGUE内で完結するので、超高速です。<br>コメント履歴は、まるごと社内の共有ナレッジに。',
      imageSrc: '/images/img_top_task02.png',
      isReverse: true
    },
    {
      number: '3',
      title: '体裁を直すスピードは、<br>ヒトの10倍',
      description:
        'ずれた番号やレイアウトを、一つひとつ手で直す。<br>そんな作業に、どれだけ時間がかかっていたでしょう。<br>LAWGUEなら、ワンクリックで修正完了。 <br>文書が長ければ長いほど、圧倒的な時短になります。',
      imageSrc: '/images/img_top_task03.png',
      isReverse: false
    }
  ]

  return (
    <section>
      <div className="text-center mx-auto sec_inner_w1120 pt-10 pb-28 maxsm:pb-16 maxsm:pt-0 maxsm:px-6 maxsm:w-full">
        <div className="relative">
          <h2 className="text-4xl font-bold text-center py-20 maxsm:text-lg maxsm:py-10 title_pickup">
            LAWGUEならこれらの課題を
            <br className="lg:hidden" />
            解決できます
          </h2>
          <span className="img_title_pickup">
            <img
              decoding="async"
              src="/images/img_title_pickup.svg"
              alt=""
              className="w-full"
            />
          </span>
        </div>
        {resolveItems.map((item, index) => (
          <ResolveItem key={index} {...item} />
        ))}
      </div>
    </section>
  )
}
